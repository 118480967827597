@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}